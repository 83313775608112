.about-me {
    height: 90vh;
    padding-top: 10vh;
}

.about-me-box {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.profile-pic {
    display: block;
    margin: 0 auto 1.5em auto;
    width: 200px;
    border-radius: 100px;
}

#about-me-text {
    margin: auto auto 1em auto;
    max-width: 600px;
    line-height: 1.5;
    text-align: left;
}

.links {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em;
}

.link+.link {
    margin-left: 0.5em;
}

.links a:link {
    color: var(--link-primary);
    text-decoration: none;
}

.links a:visited {
    color: var(--link-primary);
    text-decoration: none;
}

.links a:hover {
    color: var(--link-hover-primary);
    text-decoration: none;
}

#see-projects-button {
    background-image: linear-gradient(to right, #DB0025 0%, #F20CA6 51%, #D40CE8 100%);
    color: #FFFFFF;
    width: 150px;
    height: 40px;
    border-color: black;
    border-style: solid;
    border-radius: 20px;
}

#see-projects-button span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#see-projects-button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#see-projects-button:hover span {
    padding-right: 15px;
}

#see-projects-button:hover span:after {
    opacity: 1;
    right: 0;
}

#see-projects-button:hover {
    cursor: pointer;
}