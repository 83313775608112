.home {
    height: 100vh;
}

#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 0;
}

.home-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    text-align: center;
}

#name-header {
    transition: 0.2s;
}

#name-header:hover {
    font-size: 82px;
    cursor: pointer;
    transition: 0.5s;
}

#go-down {
    filter: invert(var(--invert));
    width: 55px;
    transition: 0.3s;
}

#go-down:hover {
    width: 60px;
    transition: 0.3s;
}